"use client"
import * as React from "react";
import { Flex, Text, breakpoint, scale } from "@ui-components";
import {
  CalendarContent,
  CalendarDayUnionType,
  BlockType,
  DateRange,
  CalendarMonth as ICalendarMonth,
  Event as EventType,
} from "../../../@types/graphql.generated";
import styled, { ThemeContext, WebTarget } from "styled-components";
import { SeasonCalendarContext } from "../context/SeasonCalendarContext";
import { useLocales, translationNamespace } from "../../../hooks/useLocales";
import { Event } from "../components";
import { getFilteredMonths } from "../utils";
import { headlineFont } from "@fonts";

export const CalendarDayWrapper = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["dayNumber", "dayMonth"].includes(prop),
})<CalendarDayUnionType>`
  border-top: 1px solid;
  border-color: ${({ theme }) =>
    theme.dark ? `rgba(255, 255, 255, 0.33)` : `rgba(0, 0, 0, 0.33)`};

  ${breakpoint("s")<CalendarDayUnionType>`
		padding: ${(props) =>
      props.__typename === "calendarDay"
        ? `${scale.px(2)}`
        : `${scale.px(1)} ${scale.px(2)}`};
	`}

  ${breakpoint("m")<CalendarDayUnionType>`
		padding: ${(props) =>
      props.__typename === "calendarDay"
        ? `${scale.px(2)} 0`
        : `${scale.px(1)} 0`};
	`}

	${breakpoint("l")<CalendarDayUnionType>`
		padding: ${(props) =>
      props.__typename === "calendarDay"
        ? `${scale.px(2.5)} 0`
        : `${scale.px(1.5)} 0`};
	`}
`;

export const Divider = styled.span`
  width: 1px;
  height: ${scale.px(1)};
  background-color: ${({ theme }) =>
    theme.dark ? `rgba(255, 255, 255, 0.33)` : `rgba(0, 0, 0, 0.33)`};
  margin: ${scale.px(1)} 0 ${scale.px(1)} 19px;

  ${breakpoint("s")`
        height: ${scale.px(2)};
        margin-left: ${scale.px(2.75)};
    `}

  ${breakpoint("l")`
        margin-left: ${scale.px(3.5)};
    `}
`;

const CalendarDayDateSpan: React.FC<DateRange> = (props) => {
  const theme = React.useContext(ThemeContext);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      className={headlineFont.className}
    >
      {props?.from?.formatted?.date === props?.to?.formatted?.date && theme ? (
        <Text
          fontSize={{ _: 2, m: 2.5, l: 3 }}
          lineHeight={{ _: 2.5, m: 3, l: 3.5 }}
          textColor={theme.dark ? "white" : "grey-dark"}
          mb={0}
        >
          {props?.from?.formatted?.date}
        </Text>
      ) : theme ? (
        <>
          <Text
            fontSize={{ _: 2, m: 2.5, l: 3 }}
            lineHeight={{ _: 2.5, m: 3, l: 3.5 }}
            textColor={theme.dark ? "white" : "grey-dark"}
            mb={0}
          >
            {props?.from?.formatted?.date}
          </Text>
          <Divider />
          <Text
            fontSize={{ _: 2, m: 2.5, l: 3 }}
            lineHeight={{ _: 2.5, m: 3, l: 3.5 }}
            textColor={theme.dark ? "white" : "grey-dark"}
            mb={0}
          >
            {props?.to?.formatted?.date}
          </Text>
        </>
      ) : null}
    </Flex>
  );
};

const CalendarDay: React.FC<CalendarDayUnionType> = (props) => {
  const theme = React.useContext(ThemeContext);
  const locales = useLocales(
    translationNamespace.block(BlockType.SeasonCalendarBlock),
    ["noShows"]
  );
  const uniqueEvents: EventType[] = [];
  const eventIds = new Set();

  if (props.__typename === "calendarDay" && props.events) {
    props.events.forEach((event) => {
      if (!eventIds.has(event.id)) {
        uniqueEvents.push(event);
        eventIds.add(event.id);
      }
    });
  }

  return (
    <CalendarDayWrapper {...props}>
      <Flex flexWrap="nowrap" w={1} p={{ _: 2, s: 0 }}>
        <Flex
          flexDirection={
            props.__typename === "calendarDay"
              ? { _: "column", s: "row" }
              : "row"
          }
          w={1}
        >
          <Flex
            flexDirection={
              props.__typename === "calendarDay"
                ? { _: "row", s: "column" }
                : "column"
            }
            minw={{ _: 7, l: 12, xl: 18 }}
          >
            {props.__typename === "calendarDay" ? (
              <>
                <div className={headlineFont.className}>
                  <Text
                    fontSize={{ _: 2, m: 2.5, l: 3 }}
                    lineHeight={{ _: 2.5, m: 3, l: 3.5 }}
                    mb={1}
                    mr={{ _: 3, s: 0 }}
                  >
                    {props.dayMonth}
                  </Text>
                </div>
                <Text
                  fontSize={{ _: 1.75, l: 2 }}
                  lineHeight={{ _: 2.5, l: 2.5 }}
                  mb={0}
                  mr={{ _: 3, s: 0 }}
                >
                  {props.name}
                </Text>
              </>
            ) : props.__typename === "dateRange" ? (
              <CalendarDayDateSpan {...props} />
            ) : null}
          </Flex>
          <Flex
            w={1}
            flexDirection={
              props.__typename === "calendarDay" ? "column" : "row"
            }
          >
            {props.__typename === "calendarDay" ? (
              uniqueEvents?.map((event, i) => (
                <Flex
                  key={event.id}
                  w={1}
                  mb={uniqueEvents && i === uniqueEvents.length - 1 ? 0 : 5}
                >
                  <Event {...event} />
                </Flex>
              ))
            ) : theme ? (
              <>
                <Flex
                  display={{ _: "none", s: "flex" }}
                  alignItems="flex-end"
                  mr={{ _: 9.25, l: 13.25, xl: 20.25 }}
                />
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  w={1}
                  className={headlineFont.className}
                >
                  <Text
                    as="h4"
                    fontSize={{ _: 2, m: 2.5, l: 3 }}
                    lineHeight={{ _: 2.5, m: 3, l: 3.5 }}
                    mb={0}
                    textColor={theme.dark ? "white" : "grey-dark"}
                  >
                    {locales.noShows}
                  </Text>
                </Flex>
              </>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </CalendarDayWrapper>
  );
};

export interface CalendarMonthProps extends ICalendarMonth {
  last: boolean;
}

const CalendarMonth: React.FC<CalendarMonthProps> = (props) => {
  const locales = useLocales(
    translationNamespace.block(BlockType.SeasonCalendarBlock),
    ["noHits"]
  );
  const { activeMonth, activeShowName, activeTag } = React.useContext(
    SeasonCalendarContext
  );

  return props.days && props.days.length > 0 ? (
    <Flex
      key={props.name}
      w={1}
      flexDirection="column"
      mb={props.last ? { _: 3, m: 5 } : 0}
      mt={{ _: 2, l: 3 }}
    >
      {!activeMonth && (
        <Text
          as="h3"
          mb={0}
          px={{ _: 2, m: 0 }}
          fontSize={{ _: 2.25, l: 2.5 }}
          lineHeight={{ _: 3.25, l: 4 }}
        >
          {props.name}
        </Text>
      )}

      {props.days.length > 0 &&
        props.days.map((day, i) =>
          day.__typename === "calendarDay" &&
          day.events &&
          day.events.length > 0 ? (
            <CalendarDay key={i + "-" + day?.events[0]?.id.replace(/\s+/g, '')} {...day} />
          ) : day.__typename === "dateRange" ? (
            <CalendarDay key={i + "-" + day?.from?.datetime + day?.to?.datetime} {...day} />
          ) : null
        )}
    </Flex>
  ) : (
    <Flex w={1} justifyContent="center" alignItems="center">
      <Text
        variant="headline-3"
        display={activeMonth || activeShowName || activeTag ? "block" : "none"}
      >
        {locales.noHits}
      </Text>
    </Flex>
  );
};

type CalendarDay = {
  __typename: string;
  events?: { [key: string]: any }[];
};

type EventsPerMonth = {
  [key: string]: {
    daysWithEvents: number;
  };
};

const Calendar: React.FC<CalendarContent> = (props) => {
  const locales = useLocales(
    translationNamespace.block(BlockType.SeasonCalendarBlock),
    ["noHits"]
  );
  const { activeMonth, activeDay, activeShowName, activeTag } =
    React.useContext(SeasonCalendarContext);

  const [filteredMonths, setFilteredMonths] = React.useState<ICalendarMonth[]>(
    props.months
  );

  React.useEffect(() => {
    const months = activeMonth ? [activeMonth] : props.months;
    setFilteredMonths(
      getFilteredMonths(months, {
        activeMonth,
        activeDay,
        activeShowName,
        activeTag,
      })
    );
  }, [activeMonth, activeDay, activeShowName, activeTag, props.months]);

  const [daysWithEventsCount, setDaysWithEventsCount] =
    React.useState<number>(0);
  const [daysWithEventsPerMonth, setDaysWithEventsPerMonth] =
    React.useState<EventsPerMonth>({});

  React.useEffect(() => {
    if (filteredMonths) {
      const eventsPerMonth: EventsPerMonth = filteredMonths.reduce(
        (acc, month) => {
          const daysWithEvents = month.days
            ? month.days.filter(
                (day) =>
                  day.__typename === "calendarDay" &&
                  day.events &&
                  day.events.length > 0
              ).length
            : 0;

          return {
            ...acc,
            [month.monthYear]: {
              daysWithEvents,
            },
          };
        },
        {} as EventsPerMonth
      );

      const totalDaysWithEvents = Object.values(eventsPerMonth).reduce(
        (acc, month) => acc + month.daysWithEvents,
        0
      );

      setDaysWithEventsCount(totalDaysWithEvents);
      setDaysWithEventsPerMonth(eventsPerMonth);
    }
  }, [filteredMonths]);

  return (
    <Flex flexDirection="column">
      {daysWithEventsCount > 0 ? (
        filteredMonths.map((month, i) =>
          daysWithEventsPerMonth &&
          daysWithEventsPerMonth[month.monthYear]?.daysWithEvents ? (
            <CalendarMonth
              key={month.monthYear}
              last={i !== filteredMonths.length - 1}
              {...month}
            />
          ) : null
        )
      ) : (
        <Flex w={1} justifyContent="center" alignItems="center">
          <Text
            variant="headline-3"
            display={
              activeMonth || activeShowName || activeTag ? "block" : "none"
            }
          >
            {locales.noHits}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export { Calendar, CalendarDay };
