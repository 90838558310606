import * as React from "react";
import {
  Flex,
  Text,
  SearchInput,
  Select,
  Chip,
  Button,
  AnimatedContainer,
  HorizontalScroller,
  INNER_MAX_WIDTH,
  ButtonProps,
} from "@ui-components";
import {
  BlockType,
  Facet,
  CalendarMonthFacet,
  CalendarDay,
  ShowFacet as IShowFacet,
} from "../../../@types/graphql.generated";
import { SeasonCalendarBlockFieldsFragment as ISeasonCalendarBlock } from "../operations.generated";
import { useLocales, translationNamespace } from "../../../hooks/useLocales";
import { SeasonCalendarContext } from "../context/SeasonCalendarContext";
import { headlineFont } from "@fonts";

export interface TagFacetsInterface {
  facets: Facet[];
  locales: Record<"allCategories" | "filterOn", string>;
}

export const TagFacets: React.FC<TagFacetsInterface> = (props) => {
  const { locales } = props;
  const defaultValue = locales.allCategories;
  const { activeTag, setActiveTag } = React.useContext(SeasonCalendarContext);

  React.useEffect(() => {
    setActiveTag("");
  }, [setActiveTag]);

  return (
    <>
      <Flex>
        <Chip
          aria-label={locales.filterOn.replace(/%s/g, defaultValue)}
          aria-pressed={activeTag === "" ? "true" : "false"}
          onClick={() => setActiveTag("")}
          className={`${activeTag === "" ? "active" : ""} ${headlineFont.className}`}
        >
          {defaultValue}
        </Chip>
      </Flex>
      {props.facets.map((tag) => (
        <Flex ml={1} key={tag.value}>
          <Chip
            aria-label={locales.filterOn.replace(/%s/g, tag.label)}
            aria-pressed={activeTag === tag.value ? "true" : "false"}
            onClick={() => setActiveTag(tag.value)}
            className={`${activeTag === tag.value ? "active" : ""} ${headlineFont.className}`}
          >
            {tag.label}
          </Chip>
        </Flex>
      ))}
    </>
  );
};

export interface ShowFacetsInterface {
  facets: IShowFacet[];
  visibleFacets: IShowFacet[];
  locales: Record<"allShows" | "filterOn", string>;
}

export const ShowFacets: React.FC<ShowFacetsInterface> = (props) => {
  const { locales } = props;
  const defaultValue = locales.allShows;
  const { activeShowName, setActiveShowName, activeTag } = React.useContext(
    SeasonCalendarContext
  );

  // Reset on render
  React.useEffect(() => {
    setActiveShowName("");
  }, [setActiveShowName]);

  return (
    <>
      {props.facets.length === props.visibleFacets.length && (
        <Flex mb={3}>
          <Chip
            aria-label={locales.filterOn.replace(/%s/g, defaultValue)}
            aria-pressed={activeShowName === "" ? "true" : "false"}
            onClick={() => {
              setActiveShowName("");
            }}
            className={`${activeShowName === "" ? "active" : ""} ${headlineFont.className}`}
          >
            {defaultValue}
          </Chip>
        </Flex>
      )}
      {props.visibleFacets.map((facet) =>
        !activeTag || facet.tag === activeTag ? (
          <Flex ml={1} mb={3} key={facet.value}>
            <Chip
              aria-label={locales.filterOn.replace(/%s/g, facet.label)}
              aria-pressed={activeShowName === facet.value ? "true" : "false"}
              onClick={() => {
                setActiveShowName(facet.value);
              }}
              className={`${activeShowName === facet.value ? "active" : ""} ${headlineFont.className}`}
            >
              {facet.label}
            </Chip>
          </Flex>
        ) : null
      )}
    </>
  );
};

const Filter: React.FC<ISeasonCalendarBlock> = (props) => {
  const showFacets = props.content?.showFacets;
  const tagFacets = props.content?.tagFacets;
  const selectMonthFacets = props.content?.selectMonthFacets;

  const seasonCalendarBlockLocales = useLocales(
    translationNamespace.block(BlockType.SeasonCalendarBlock),
    [
      "allCategories",
      "allDays",
      "allMonths",
      "allShows",
      "search",
      "searchShow",
      "searchShowName",
      "month",
      "day",
    ]
  );
  const commonLocales = useLocales(translationNamespace.common("Filter"), [
    "filterOn",
    "filterLabel",
  ]);
  const locales = { ...seasonCalendarBlockLocales, ...commonLocales };

  // Toggle for filter
  const [filterOpen, setFilterOpen] = React.useState(false);

  const { activeMonth, setActiveMonth, setActiveDay } = React.useContext(
    SeasonCalendarContext
  );

  const [activeMonthFacet, setActiveMonthFacet] =
    React.useState<CalendarMonthFacet | null>(null);

  // Show facet search query
  const [showFacetQuery, setShowFacetQuery] = React.useState("");

  // State for filtered show facets using search
  const [visibleShowFacets, setVisibleShowFacets] = React.useState(
    showFacets ?? []
  );

  React.useEffect(() => {
    if (showFacets) {
      setVisibleShowFacets(() =>
        showFacets.filter(
          (f) =>
            f.value.toLowerCase().search(showFacetQuery.toLowerCase()) !== -1
        )
      );
    }
  }, [showFacets, showFacetQuery]);

  React.useEffect(() => {
    if (activeMonthFacet) {
      const foundMonth = props?.content?.months.find(
        (m) => m.monthYear === activeMonthFacet.value
      );
      setActiveMonth(foundMonth || null);
    } else {
      setActiveMonth(null);
    }
  }, [props?.content?.months, setActiveMonth, activeMonthFacet]);

  const filterBtnCommonProps: ButtonProps = {
    border: true,
    borderWidth: `2px`,
    borderRadius: 4,
    borderColor: "black",
    icon: "funnel",
  };

  return (
    <>
      <Flex
        w={1}
        justifyContent="space-between"
        alignItems="flex-end"
        px={{ _: 2, m: 0 }}
      >
        <Flex w={1}>
          {/* Month select */}
          {selectMonthFacets && props.theme && (
            <Flex
              flexDirection="column"
              mr={{ _: 3, l: 7.25 }}
              maxw={{ _: 18, l: 31 }}
              w={1}
              justifyContent="center"
            >
              <Text
                as="label"
                htmlFor="month-select"
                fontSize={{ _: 1.75, l: 2.5 }}
                lineHeight={{ _: 3.25, l: 4 }}
                mb={0}
              >
                {locales.month}
              </Text>
              <Select
                id={"month-select"}
                onChange={(e) => {
                  setActiveMonthFacet(
                    selectMonthFacets.find(
                      (m) => m.value === e.currentTarget.value
                    ) || null
                  );
                  // Reset day
                  setActiveDay(null);
                }}
                options={[
                  { value: null, label: locales.allMonths },
                  ...selectMonthFacets.filter((facet) => !facet.disabled),
                ]}
                fontSize={{ _: 2.25, l: 4 }}
                lineHeight={{ _: 3, l: 5 }}
                className={headlineFont.className}
                borderBottom
                borderColor={props.theme.dark ? "white" : "black"}
              />
            </Flex>
          )}
          {/* Day select - only visible if user has chosen a month */}
          {selectMonthFacets &&
            activeMonth &&
            activeMonthFacet &&
            props.theme && (
              <Flex
                flexDirection="column"
                maxw={{ _: 18, l: 33 }}
                w={1}
                justifyContent="center"
              >
                <Text
                  as="label"
                  htmlFor="day-select"
                  fontSize={{ _: 1.75, l: 2.5 }}
                  lineHeight={{ _: 3.25, l: 4 }}
                  mb={0}
                >
                  {locales.day}
                </Text>
                <Select
                  id={"day-select"}
                  onChange={(e) => {
                    if (activeMonth.days) {
                      setActiveDay(
                        activeMonth.days.find(
                          (d) =>
                            d.__typename === "calendarDay" &&
                            d.dayNumber === parseInt(e.currentTarget.value)
                        ) as CalendarDay
                      );
                    }
                  }}
                  options={[
                    { value: "allDays", label: locales.allDays },
                    ...activeMonthFacet.days.filter((facet) => !facet.disabled),
                  ]}
                  fontSize={{ _: 2.25, l: 4 }}
                  lineHeight={{ _: 3, l: 5 }}
                  className={headlineFont.className}
                  borderBottom
                  borderColor={props.theme.dark ? "white" : "black"}
                />
              </Flex>
            )}
        </Flex>

        {/* Filter toggle */}
        {showFacets && tagFacets && (
          <Flex>
            <Button
              size="icon"
              display={{ _: "none", m: "block" }}
              backgroundColor={filterOpen ? "white" : "black"}
              textColor={filterOpen ? "black" : "white"}
              textTransform="uppercase"
              onClick={() => setFilterOpen(!filterOpen)}
              iconPosition="left"
              label={locales.filterLabel}
              {...filterBtnCommonProps}
            />
            <Button
              display={{ _: "block", m: "none" }}
              backgroundColor={filterOpen ? "white" : "black"}
              textColor={filterOpen ? "black" : "white"}
              textTransform="uppercase"
              onClick={() => setFilterOpen(!filterOpen)}
              iconPosition="center"
              size="icon"
              aria-label={locales.filterLabel}
              {...filterBtnCommonProps}
            />
          </Flex>
        )}
      </Flex>
      {showFacets && tagFacets && (
        <Flex
          w={1}
          flexDirection="column"
          style={{ overflow: "hidden" }}
          pl={{ _: 2, m: 0 }}
        >
          <AnimatedContainer active={filterOpen}>
            {/* Tag facets - mobile variant with horizontal scroll */}
            <Flex
              flexDirection="column"
              w={1}
              maxw={INNER_MAX_WIDTH}
              style={{ margin: "0 auto" }}
              py={{ _: 0, l: 6 }}
            >
              {tagFacets && (
                <>
                  <Flex
                    w={1}
                    mb={{ _: 3, l: 4 }}
                    justifyContent="center"
                    display={{ _: "none", l: "flex" }}
                  >
                    <TagFacets facets={tagFacets} locales={locales} />
                  </Flex>
                  <Flex
                    w={1}
                    mb={{ _: 0, l: 4 }}
                    justifyContent="center"
                    display={{ _: "block", l: "none" }}
                  >
                    <HorizontalScroller
                      justifyContent={{ _: "flex-start", m: "flex-end" }}
                    >
                      <TagFacets facets={tagFacets} locales={locales} />
                    </HorizontalScroller>
                  </Flex>
                </>
              )}

              {/* Show facets search */}
              <Flex
                w={1}
                maxw={80}
                flexDirection="column"
                alignItems="center"
                style={{ marginLeft: "auto", marginRight: "auto" }}
                mb={3}
                pr={{ _: 2, m: 0 }}
                className={headlineFont.className}
              >
                <Text
                  as="label"
                  htmlFor="showSearch"
                  fontSize={{ _: 2.25, l: 3 }}
                  lineHeight={{ _: 3, l: 4 }}
                  mb={{ _: 1, l: 2 }}
                >
                  {locales.searchShow}
                </Text>
                <SearchInput
                  id="showSearch"
                  label={locales.search}
                  placeholder={locales.searchShowName} // translate
                  value={showFacetQuery}
                  changeHandler={(e) => setShowFacetQuery(e.target.value)}
                  onCancel={() => setShowFacetQuery("")}
                  variant="small"
                />
              </Flex>

              {/* Show facets - mobile variant with horizontal scroll */}
              {showFacets?.length > 0 && (
                <>
                  <Flex
                    w={1}
                    display={{ _: "none", l: "flex" }}
                    justifyContent="center"
                    flexWrap="wrap"
                  >
                    <ShowFacets
                      facets={showFacets}
                      visibleFacets={visibleShowFacets}
                      locales={locales}
                    />
                  </Flex>
                  <Flex
                    w={1}
                    mb={{ _: 0, l: 4 }}
                    justifyContent="center"
                    display={{ _: "block", l: "none" }}
                  >
                    <HorizontalScroller
                      justifyContent={{ _: "flex-start", m: "flex-end" }}
                    >
                      <ShowFacets
                        facets={showFacets}
                        visibleFacets={visibleShowFacets}
                        locales={locales}
                      />
                    </HorizontalScroller>
                  </Flex>
                </>
              )}
            </Flex>
          </AnimatedContainer>
        </Flex>
      )}
    </>
  );
};

export { Filter };
