"use client";
import React, { useRef, useCallback } from "react";
import {
  CustomLink,
  Flex,
  INNER_MAX_WIDTH,
  MegaMenu as UIMegaMenu,
  CustomNoLink,
} from "@ui-components";
import { Megamenu as IMegaMenu } from "../../@types/graphql.generated";
import { UIContext } from "@/context";
import { MenuProps } from "./Menu";
import { translationNamespace, useLocales } from "@/hooks";
import MegaMenuItem from "./components/MegaMenuItem";
import { headlineFont } from "@/app/fonts";

const MIN_MENU_HEIGHT = { _: 0, l: 40, xxl: 30 };

const MegaMenu: React.FC<MenuProps> = (props) => {
  const { currentOpenMegaMenu, setCurrentMegaMenu } =
    React.useContext(UIContext);
  const locales = useLocales(translationNamespace.common("Header"), [
    "closeMegaMenuLabel",
  ]);
  const ref = useRef<HTMLDivElement>(null);

  const findMenuItem = useCallback(
    (menu: IMegaMenu[], itemId?: string): IMegaMenu | undefined => {
      for (const item of menu) {
        if (item.id === itemId) return item;
        const foundChild = findMenuItem(item.children || [], itemId);
        if (foundChild) return foundChild;
      }
      return undefined;
    },
    []
  );

  const currentMegaMenu = findMenuItem(
    props.menu?.data?.megamenu as IMegaMenu[],
    currentOpenMegaMenu
  );
  const menuItems =
    currentMegaMenu?.children ?? props.menu?.data?.megamenu ?? [];

  return (
    <UIMegaMenu
      id="mega-menu"
      open={!!currentOpenMegaMenu}
      openAtHeight={props.openAt}
    >
      <Flex
        maxw={INNER_MAX_WIDTH}
        style={{ margin: "0 auto", opacity: props.menu?.loading ? 0 : 1 }}
      >
        <Flex flex={{ _: 1, xxl: 1 }} flexDirection="column" w="100%">
          {currentMegaMenu && (
            <Flex pb={6} style={{ fontSize: 5 }} mb={0} className={headlineFont.className}>
              {currentMegaMenu.url ? (
                <CustomLink
                  href={currentMegaMenu.url}
                  label={currentMegaMenu.title.toUpperCase()}
                  withChevron
                />
              ) : (
                <CustomNoLink
                  label={currentMegaMenu.title.toUpperCase()}
                  withChevron={false}
                />
              )}
            </Flex>
          )}
          <Flex ref={ref} flexWrap={"wrap"} alignItems={"flex-start"} h="auto">
            {menuItems.map((item, index) =>
              item ? (
                <MegaMenuItem
                  key={`mega-menu-item-${index}`}
                  item={item as IMegaMenu}
                />
              ) : (
                <></>
              )
            )}
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          flex={{ _: 0, xxl: "inherit" }}
          display={{ _: "none" /*xxl: "flex"*/ }}
          minh={MIN_MENU_HEIGHT}
          backgroundColor="white"
        >
          {/* Placeholder for aside content */}
        </Flex>
      </Flex>
      <UIMegaMenu.Toggle
        onClick={() => setCurrentMegaMenu(undefined)}
        open={!!currentOpenMegaMenu}
        closeLabel={locales.closeMegaMenuLabel}
      />
    </UIMegaMenu>
  );
};

export { MegaMenu };
