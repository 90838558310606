"use client";
import * as React from "react";
import { usePathname } from "next/navigation";
import Image from "next/image";
import {
  Text,
  Flex,
  PromotionalArea,
  INNER_CONTAINER_MAX_WIDTH,
  READABLE_LINE_MAX_WIDTH,
} from "@ui-components";
import { qBankImageSrc } from "../../utils";
import {
  PageIntroBlock as IPageIntroBlock,
  BlockType,
} from "../../@types/graphql.generated";
import { renderSubnav } from "./utils";
import { ThemeContext } from "styled-components";
import { useLocales, translationNamespace } from "../../hooks/useLocales";
import { headlineFont } from "@fonts";

const PageIntroBlock: React.FC<IPageIntroBlock> = (props) => {
  const pathname = usePathname();
  const themeContext = React.useContext(ThemeContext);
  const locales = useLocales(
    translationNamespace.block(BlockType.PageIntroBlock),
    ["submenuAccordionToggleLabel"]
  );
  const showMenu =
    props.menu && props.menu.children && props.menu.children.length > 0;
  const campaignArea = props.campaignArea;
  const showCampaignArea = campaignArea && campaignArea.campaignAreaShowHide;
  const campaignAreaTitle = campaignArea?.campaignAreaTitle;

  return (
    <Flex px={{ _: 2, m: 5, l: 10 }} pt={{ _: 4, m: 6, l: 8 }}>
      <Flex
        alignItems="center"
        flexDirection="column"
        w={1}
        maxw={INNER_CONTAINER_MAX_WIDTH}
        style={{ margin: "0 auto" }}
      >
        <Flex w={1} mt={{ _: -4, m: -6 }} alignItems={"flex-start"}>
          <Flex
            w={1}
            style={{ position: "relative" }}
            mt={{ _: props.menu ? 12 : 7, l: 8 }}
            flexDirection={{ _: "column", l: "row" }}
            pr={
              showMenu ? { _: 0, l: 39, xl: 43, xxl: 44, xxxl: 47 } : undefined
            }
          >
            <Flex display="block" w={1} maxw={READABLE_LINE_MAX_WIDTH}>
              <Flex flexDirection="column" className={headlineFont.className}>
                <Text as="h1" variant="headline-1">
                  {props.title}
                </Text>
                {props.preamble && (
                  <Text
                    variant="preamble"
                    mb={props.image ? { _: 3.25, m: 3.5 } : 0}
                    fontSize={{ _: 2.375, m: 2.75, l: 4 }}
                    lineHeight={{ _: 3.5, m: 3.75, l: 5 }}
                  >
                    {props.preamble}
                  </Text>
                )}
                <Flex display="block">
                  {props.image && props.image.src && (
                    <Image
                      alt={props.image.alt || ""}
                      width="768"
                      height="512"
                      src={qBankImageSrc(props.image.src, "768")}
                      style={{ width: "100%", height: "100%" }}
                      sizes="(max-width: 384px) 384px, (min-width: 385px) and (max-width: 640px) 640px, 768px"
                      priority
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
            {showCampaignArea && campaignAreaTitle && (
              <Flex
                maxw={{ _: "unset", s: 50 }}
                minw={{ _: "unset", s: 40 }}
                pt={{ _: 4, l: 0 }}
                pl={{ _: 0, l: 5 }}
              >
                <PromotionalArea
                  media={
                    props?.campaignArea?.campaignAreaImage &&
                    props.campaignArea.campaignAreaImage.src ? (
                      <Image
                        alt={props.campaignArea.campaignAreaImage.alt || ""}
                        width="768"
                        height="512"
                        src={qBankImageSrc(
                          props.campaignArea.campaignAreaImage.src,
                          "768"
                        )}
                        sizes="(max-width: 384px) 384px, (min-width: 385px) and (max-width: 640px) 640px, 768px"
                        priority
                      />
                    ) : undefined
                  }
                  title={campaignAreaTitle}
                  text={props?.campaignArea?.campaignAreaText || undefined}
                  link={props?.campaignArea?.campaignAreaLink || undefined}
                  color={
                    props?.campaignArea?.campaignAreaCardColor || undefined
                  }
                  darkMode={
                    props?.campaignArea?.campaignAreaDarkMode || undefined
                  }
                />
              </Flex>
            )}
            {showMenu &&
              props.menu &&
              themeContext &&
              renderSubnav(props.menu, pathname, themeContext, locales)}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { PageIntroBlock };
