"use client";
import { useRouter } from "next/navigation";
import { motion } from "framer-motion";
import {
  SearchInput,
  Flex,
  Text,
  INNER_CONTAINER_MAX_WIDTH,
  CUBIC_BEZIER,
} from "@ui-components";
import * as React from "react";
import { BlockType } from "../../@types/graphql.generated";
import { translationNamespace, useLocales } from "../../hooks";
import { localeCodeFromUrl } from "@/utils/locale";
import { LinkListItemLinkStyled } from "./styled";
import { useSuspenseQuery } from "@apollo/client";
import { GetCommonSearchResultsDocument, GetCommonSearchResultsQueryResult } from "./operations.generated";

interface SearchModalContentProps {
  handleClose: () => void;
}

export const SearchModalContent: React.FC<SearchModalContentProps> = ({
  handleClose,
}) => {
  const router = useRouter();

  const locales = useLocales(
    translationNamespace.block(BlockType.SearchBlock),
    ["searchFieldLabel", "searchFieldPlaceholder", "searchFieldCancelLabel"]
  );

  const commonSearchResultsQuery = GetCommonSearchResultsDocument;

  const commonSearchResults = useSuspenseQuery(
    commonSearchResultsQuery
  ) as GetCommonSearchResultsQueryResult;

  const commonSearchResultProps =
    commonSearchResults?.data?.getCommonSearchResults;

  // Focus on InputField when modal opens
  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  React.useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  // Set by onChange event in search form
  const [searchInput, setSearchInput] = React.useState("");

  // If user clears search field with native clear button
  const onCancel = () => {
    setSearchInput("");
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const localeCode = localeCodeFromUrl(window.location.pathname);
    const pathname = localeCode === "sv-se" ? "/sok/" : "/en/search/";
    const search = `?q=${searchInput}&page=1`;
    router.push(pathname + search);
    handleClose();
  };

  // Framer motion
  const container = {
    show: {
      transition: { staggerChildren: 0.05, delayChildren: 0.5 },
    },
    hidden: {
      transition: { staggerChildren: 0.02, staggerDirection: -1 },
    },
  };

  const item = {
    show: {
      y: 0,
      opacity: 1,
    },
    hidden: {
      y: 7,
      opacity: 0,
    },
  };

  if (typeof window === "undefined") return null;

  return (
    <Flex px={{ _: 2, m: 5, l: 10 }} pt={{ _: 15.25, l: 20.25 }} pb={4} w={1}>
      <Flex
        maxw={INNER_CONTAINER_MAX_WIDTH}
        w={1}
        style={{ margin: "0 auto" }}
        flexDirection="column"
      >
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{
            opacity: 0,
            y: 0,
            transition: { ease: [CUBIC_BEZIER], duration: 0.5 },
          }}
          transition={{ ease: [CUBIC_BEZIER], duration: 1 }}
        >
          <form onSubmit={onSubmit}>
            <SearchInput
              ref={ref}
              label={locales.searchFieldLabel}
              placeholder={locales.searchFieldPlaceholder}
              value={searchInput}
              changeHandler={(e) => {
                setSearchInput(e.currentTarget.value);
              }}
              onCancel={onCancel}
              cancelButtonLabel={locales.searchFieldCancelLabel}
            />
          </form>
        </motion.div>
        {commonSearchResultProps &&
          commonSearchResultProps.title &&
          commonSearchResultProps.results && (
            <motion.div
              initial={{ opacity: 0, scaleY: 0, y: 1 }}
              animate={{
                opacity: 1,
                scaleY: 1,
                y: 0,
                transition: {
                  ease: [CUBIC_BEZIER],
                  duration: 0.8,
                  delay: 0.2,
                },
              }}
              exit={{
                opacity: 0,
                scaleY: 0,
                y: 1,
                transition: {
                  ease: [CUBIC_BEZIER],
                  duration: 0.8,
                },
              }}
            >
              <Flex
                flexDirection="column"
                backgroundColor="off-white"
                my={1}
                px={{ _: 1.75, l: 4 }}
                py={{ _: 4, l: 4.25 }}
              >
                <Text variant="headline-3" textColor="black">
                  {commonSearchResultProps.title}
                </Text>

                <Flex as="nav">
                  <motion.ul
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    variants={container}
                  >
                    {commonSearchResultProps.results.map(
                      (link) =>
                        link?.url && (
                          <motion.li key={link?.title} variants={item}>
                            <LinkListItemLinkStyled
                              href={link?.url}
                              textColor="black-light"
                              fontSize={2.25}
                              lineHeight={4}
                              mb={0}
                              onClick={handleClose}
                              prefetch={false}
                            >
                              {link?.title}
                            </LinkListItemLinkStyled>
                          </motion.li>
                        )
                    )}
                  </motion.ul>
                </Flex>
              </Flex>
            </motion.div>
          )}
      </Flex>
    </Flex>
  );
};
