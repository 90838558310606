import { bodyFont, headlineFont } from "@fonts";
import { breakpointsMap, colors, scale } from "@/components/ui-components/src";
import { emailValidator } from "@/utils/emailValidator";
import React from "react"

const NEWSLETTER_FORM_ID = process.env.NEXT_PUBLIC_NEWSLETTER_FORM_ID ?? "";
const NEWSLETTER_FORM_URL = process.env.NEXT_PUBLIC_NEWSLETTER_FORM_URL ?? "";

const createMarketHypeStyle = () => {
	const style = document.createElement('style');
	style.textContent = `
		.mhForm {
			font-family: ${bodyFont.style.fontFamily} !important;
		}
		.mhForm__input--email {
			width: 100% !important;
			min-width: 0;
			align-self: stretch;
			font-size: ${scale.px(2)} !important;
			color: ${colors["black"]} !important;
			text-align: left;
			background-color: rgb(255, 255, 255) !important;
			-moz-appearance: textfield;
			border: 2px solid ${colors["black"]}!important;
			padding: ${scale.px(1.5)}!important;
			border-radius: 0!important;
			line-height: ${scale.px(4)}!important;
		
			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		
			&[disabled] {
				opacity: 0.5;
			}
		}
		.mhForm__input--checkbox {
			width: ${scale.px(3)};
			height: ${scale.px(3)};
			margin: ${scale.px(1)} !important; 
			align-self: stretch;
			font-size: ${scale.px(4)};
			text-align: center;
			background-color: rgba(255, 255, 255, 0.6);
			-moz-appearance: textfield;
			border: none;
			padding: ${scale.px(1.5)} 0;
			accent-color: ${colors["cyan-blue"]};

			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			&[disabled] {
				opacity: 0.5;
			}
		}
		.mhForm__checkboxLabel {
			font-size: ${scale.px(2)};
			color: ${colors["black"]};
			text-align: left;
		}
		.mhForm__checkboxLabel a {
			color: ${colors["black"]};
			text-decoration: underline;
		}
		.mhForm__button {
			color: ${colors["black"]} !important;
			background-color: ${colors["cyan-blue"]} !important;
			font-size: ${scale.px(2)} !important;
			line-height: ${scale.px(4)};
			font-weight: 500 !important;
			margin-left: ${scale.px(2)} !important;
            font-family: ${headlineFont.style.fontFamily} !important;   
		}
		.mhCustomFlex__container {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		@media (max-width: ${breakpointsMap.m}px) {
			.mhCustomFlex__container {
				flex-direction: column;
			}
			.mhForm__control--email {
				width: 100% !important;
			}
			.mhForm__control--button {
				width: 100% !important;
				margin: 0 !important;
			}
			.mhForm__button {
				width: 100% !important;
				text-align: center;
				margin-left: 0 !important;
			}
		}
		.mhForm__success {
			display: none !important;
		}
		.mhForm__label--email {
			display: none !important;
		}
		.mhForm__control > span {
			display: none !important;
		}
		.mhForm__validationError {
			display: none !important;
		}
		.mhForm__error {
			display: none !important;
		}
	`;
	document.head.appendChild(style);
}

let externalScriptLoaded = false;

export const useNewsletterForm = (
    ref: React.RefObject<HTMLElement>, 
    buttonText: string,
    privacyPolicyInformation: string,
    placeholderText: string
) => {
    const [notificationType, setNotificationType] = React.useState<'success' | 'error' | 'invalid' | null>(null);

    React.useEffect(() => {
        const divRef = ref.current;
        if (!divRef) return;

        const handleInvalid = (e: Event): void => {
            e.preventDefault();
            setNotificationType('invalid');
        };

        const handleInput = (e: Event): void => {
            setNotificationType(null);
        };
        
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList' && divRef?.contains(mutation.target as Node)) {
                    observer.disconnect();
                        
                    createMarketHypeStyle();
                    const form = document.querySelector('.mhForm') as HTMLInputElement;
                    const emailField = document.querySelector('.mhForm__input--email') as HTMLInputElement;
                    const emailControlField = document.querySelector('.mhForm__control--email') as HTMLInputElement;
                    const buttonControl = document.querySelector('.mhForm__control--button') as HTMLButtonElement;
                    const buttonField = document.querySelector('.mhForm__button') as HTMLButtonElement;
                    const checkboxField = document.querySelector('.mhForm__input--checkbox') as HTMLInputElement;
                    const checkboxLabel = document.querySelector('.mhForm__checkboxLabel') as HTMLSpanElement;

                    // Moving the email field and button to be able to flex them
                    if (form && emailControlField && buttonControl) {
                        const newDiv = document.createElement('div');
                        newDiv.className = 'mhCustomFlex__container';

                        newDiv.appendChild(emailControlField);
                        newDiv.appendChild(buttonControl);
                        emailControlField.style.flex = '1';

                        form.insertBefore(newDiv, form.firstChild);
                    } else {
                        console.error('Required elements not found');
                    }
                    
                    // Inserting the privacy policy information
                    if(checkboxLabel) {
                        checkboxLabel.innerHTML = privacyPolicyInformation;
                    } else {
                        console.error('Privacy policy not found');
                    }

                    // Inserting the privacy policy information
                    if(buttonField) {
                        buttonField.innerHTML = buttonText.toUpperCase();
                    } else {
                        console.error('Button element not found');
                    }

                    // Override the form submit events
                    if (form) {
                        form.addEventListener('submit', (e: Event) => {
                            if (!emailField.value || !checkboxField.checked || !emailValidator(emailField.value)) {
                                e.preventDefault();
                                e.stopPropagation();
                                setNotificationType('invalid');
                            } else {
                                setNotificationType('success');
                            }
                        });
                    } else {
                        console.error('form not found');
                    }

                    // Override the input/invalid events from email and checkbox fields
                    if (emailField) {
                        emailField.oninput = handleInput;
                        emailField.oninvalid = handleInvalid;
                        emailField.setAttribute("placeholder", placeholderText);
                    } else {
                        console.error('emailField not found');
                    }
                    if (checkboxField) {
                        checkboxField.oninput = handleInput;
                        checkboxField.oninvalid = handleInvalid;
                    } else {
                        console.error('checkboxField not found');
                    }
                }
            });
        });


        if (!document.querySelector(`script[src="${NEWSLETTER_FORM_URL}"]`) && !externalScriptLoaded) {

            // Load the third-party script dynamically
            const script = document.createElement('script');
            script.src = NEWSLETTER_FORM_URL;
            script.async = true;

            // Handle the script events
            script.onload = () => {
                if (divRef && !externalScriptLoaded) {
                    (window as any).mhForm.create({ formId: NEWSLETTER_FORM_ID, insert: "append", target: divRef });
                    observer.observe(divRef, { childList: true, subtree: true });
                    externalScriptLoaded = true;
                }
            };
            script.onerror = () => {
                if(!externalScriptLoaded) return;
                setNotificationType('error');
            };

            // Add the script to the document
            document.body.appendChild(script);
        }

        return () => {
            externalScriptLoaded = false;
            const script = document.querySelector(`script[src="${NEWSLETTER_FORM_URL}"]`);
            if (script) {
                script.remove();
            }
			if (divRef) {
				divRef.innerHTML = '';
			}
            observer.disconnect();
        };
    }, [ref, buttonText, privacyPolicyInformation, placeholderText]);

    return notificationType;
}