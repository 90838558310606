"use client";
import React from "react";
import { MenuProps } from "..";
import { ThemeContext } from "styled-components";
import { UIContext } from "@/context";
import { Flex, Icon, Text } from "@ui-components";
import { headlineFont } from "@/app/fonts";

const MyProfile: React.FC<MenuProps> = (props) => {
    const theme = React.useContext(ThemeContext);
    const { toggleMegaMenu, toggleNavFoldout } = React.useContext(UIContext);
  
    return (
      <>
        {props?.menu?.data?.main &&
          props?.menu?.data.main.map((item, i) =>
            item?.linkType === "MY_PROFILE" &&
            typeof theme?.dark === "boolean" ? (
              <React.Fragment key={i + "-" + item.url}>
                {/* MyProfile link desktop */}
                <Flex
                  display={{ _: "none", xl: "flex" }}
                  mr={2.5}
                  alignItems="center"
                >
                  <a aria-label={item.title} href={item.url}>
                    <Text
                      onClick={() => toggleMegaMenu(false)}
                      fontSize={{ xl: 1.75, xxl: 2 }}
                      fontWeight="semibold"
                      lineHeight={2}
                      textColor={theme.dark ? "white" : "black"}
                      textTransform="uppercase"
                      mb={0}
                    >
                      <Flex as="span" alignItems="center" className={headlineFont.className}>
                        {item.title}
                        <Icon
                          icon="profile"
                          size={2.5}
                          ml={1}
                          mt={-0.25}
                          color={theme.dark ? "white" : "black"}
                        />
                      </Flex>
                    </Text>
                  </a>
                </Flex>
                {/* MyProfile link mobile */}
                <Flex display={{ _: "flex", xl: "none" }} mr={2}>
                  <a
                    aria-label={item.title}
                    href={item.url}
                    onClick={() => toggleNavFoldout(false)}
                  >
                    <Icon
                      icon="profile"
                      size={3}
                      ml={1}
                      mt={{ _: 0, l: -0.25 }}
                      color={theme.dark ? "white" : "black"}
                    />
                  </a>
                </Flex>
              </React.Fragment>
            ) : null
          )}
      </>
    );
};
  
export { MyProfile };