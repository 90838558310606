"use client";
import React from "react";
import { GetMenuQueryResult } from "./operations.generated";
import { Megamenu as IMegaMenu } from "../../@types/graphql.generated";
import { UIContext } from "@/context";
import { usePathname } from "next/navigation";
import useSSR from "use-ssr";
import { translationNamespace, useLocales } from "@/hooks";
import { Flex, NavToggle, Menu as UIMenu } from "@ui-components";
import {
  trackMainMenuClickEvent,
} from "./utils";
import { headlineFont } from "@/app/fonts";

export interface MenuProps {
  menu?: GetMenuQueryResult;
  openAt?: number;
}

// Main menu mobile & desktop
const mapMenuItems = (
  menu: IMegaMenu[],
  path: string,
  setOpenMegaMenu: (id: string|undefined) => void,
  openMegaMenu: string|undefined,
  toggleNavFoldout: (state: boolean) => void,
  trackMainMenuClickEvent: (linkTitle: string) => void,
  headerLocales: Record<string, string>
) => {
  return menu.map((item, idx) => {
    const hasChildren = item?.children?.length ?? 0 > 0;
    if (hasChildren) {
      const megaMenuOpen = openMegaMenu 
        ? item.id === openMegaMenu
        : false;
      return (
        <UIMenu.Item key={item.id} index={idx} active={path === item.url}>
          <UIMenu.DropdownLink active={path === item.url || megaMenuOpen}>
            <Flex
              key={item.id}
              display={{ _: "none", xl: "flex" }}
              style={{ textAlign: "center" }}
            >
              <NavToggle
                open={megaMenuOpen}
                onClick={() => setOpenMegaMenu(megaMenuOpen ? undefined : item.id)}
                aria-label={`${headerLocales?.openMenuLinksFor} ${item.title}`}
                locales={{
                  label: item.title,
                }}
                ariaControls="mega-menu"
                chevron={true}
                className={headlineFont.className}
              />
            </Flex>
          </UIMenu.DropdownLink>
        </UIMenu.Item>
      );
    }
    return (
      <UIMenu.Item key={item.id} index={idx} active={path === item.url}>
        {item?.url && (
          <Flex
            display={{ _: "none", xl: "flex" }}
            style={{ textAlign: "center" }}
            mr={1}
          >
            <UIMenu.Link
              href={item.url}
              onClick={() => {
                toggleNavFoldout(false);
                setOpenMegaMenu(undefined);
                trackMainMenuClickEvent(item.title);
              }}
              active={path === item.url}
              className={headlineFont.className}
            >
                {item.title}
            </UIMenu.Link>
          </Flex>
        )}
      </UIMenu.Item>
    );
  });
}

const Menu: React.FC<MenuProps> = React.memo((props) => {
  const { navFoldoutOpen, toggleNavFoldout, currentOpenMegaMenu, setCurrentMegaMenu } =
    React.useContext(UIContext);
  const pathname = usePathname();
  const { isBrowser } = useSSR();
  const headerLocales = useLocales(translationNamespace.common("Header"), [
    "openMegaMenuLabel",
    "openMenuLinksFor"
  ]);

  React.useEffect(() => {
    if (isBrowser) {
      document.body.style.overflow = navFoldoutOpen ? "hidden" : "auto";
      document.body.style.height = navFoldoutOpen ? "100%" : "auto";
    }
  }, [navFoldoutOpen, isBrowser]);

  return (
    <Flex display="block" style={{ opacity: props?.menu?.loading ? 0 : 1 }} mr={1}>
      <UIMenu open={!!currentOpenMegaMenu}>
        <UIMenu.List style={{flexWrap: "wrap"}}>
          {props?.menu?.data?.megamenu &&
            mapMenuItems(
              props.menu.data.megamenu as IMegaMenu[],
              pathname,
              setCurrentMegaMenu,
              currentOpenMegaMenu,
              toggleNavFoldout,
              trackMainMenuClickEvent,
              headerLocales
            )
          }
        </UIMenu.List>
      </UIMenu>
    </Flex>
  );
});
Menu.displayName = "Menu";

export { Menu };
