import * as React from "react";
import Link from "next/link";
import Image from "next/image";
import { SearchResultItem, Text } from "@ui-components";
import { CommonSearchResultItem } from "../../../@types/graphql.generated";
import { translationNamespace, useLocales } from "../../../hooks";
import { qBankImageSrc } from "../../../utils";
import { headlineFont } from "@fonts";

interface SearchResultitemProfileProps {
  result: CommonSearchResultItem;
  index: number;
}

export const SearchResultItemProfile = React.forwardRef<
  HTMLAnchorElement,
  SearchResultitemProfileProps
>((props, ref) => {
  const locales = useLocales(translationNamespace.common("Misc"), [
    "email",
    "telephone",
  ]);
  return (
    <SearchResultItem
      image={
        props.result.image && props.result.image.src ? (
          <Image
            src={qBankImageSrc(props.result.image.src, "768")}
            alt={props.result.image.alt || ""}
            width="768"
            height="512"
            sizes="(max-width: 639px) 256px, 384px"
            priority={props.index <= 1}
          />
        ) : null
      }
    >
      <SearchResultItem.Header>
        {props.result.tag && (
          <SearchResultItem.Tag shouldTruncate>
            {props.result.tag}
          </SearchResultItem.Tag>
        )}
      </SearchResultItem.Header>
      {props.result.title && (
        <Link
          ref={ref}
          href={props.result.title.url}
          className={headlineFont.className}
          prefetch={false}
        >
          <Text
            as="h2"
            fontSize={{ _: 2.25, m: 3, xl: 4 }}
            lineHeight={{ _: 3, m: 4, xl: 5 }}
            textColor="black"
            mb={{ _: 0, m: 0.5 }}
            style={{ textDecoration: "underline" }}
          >
            {props.result.title.title}
          </Text>
        </Link>
      )}

      {props.result.role && (
        <Text
          fontSize={{ _: 1.5, m: 1.75 }}
          lineHeight={2.25}
          textTransform="uppercase"
          mb={{ _: 1, m: 4 }}
        >
          {props.result.role}
        </Text>
      )}

      {(props.result.phone || props.result.email) && (
        <SearchResultItem.Content>
          {props.result.phone && (
            <SearchResultItem.ContactLink href={`tel:${props.result.phone}`}>
              {locales.telephone}: {props.result.phone}
            </SearchResultItem.ContactLink>
          )}
          {props.result.email && (
            <SearchResultItem.ContactLink href={`mailto:${props.result.email}`}>
              {locales.email}: {props.result.email}
            </SearchResultItem.ContactLink>
          )}
        </SearchResultItem.Content>
      )}
    </SearchResultItem>
  );
});
SearchResultItemProfile.displayName = "SearchResultItemProfile";
