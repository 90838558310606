"use client"
import { UIContext } from "@/context";
import { MenuProps } from "..";
import React from "react";
import { Button, Flex } from "@ui-components";
import { usePathname } from "next/navigation";
import { trackTicketLinkClickEvent } from "../utils";
import { headlineFont } from "@/app/fonts";

const CalendarButton: React.FC<MenuProps> = (props) => {
    const { toggleMegaMenu } = React.useContext(UIContext);
    const pathname = usePathname();
  
    return (
      <Flex display="block" style={{ opacity: props?.menu?.loading ? 0 : 1 }} className={headlineFont.className}>
        {props?.menu?.data?.main &&
          props?.menu?.data.main.map((item) =>
            item?.linkType === "TICKET_PAGE" ? (
              <Button.Link
                size={{_: "large", xl: "xlarge"}}
                backgroundColor="cyan-blue"
                textColor="black"
                href={item.url}
                label={item.title}
                onClick={() => {
                  toggleMegaMenu(false);
                  trackTicketLinkClickEvent(item.title, pathname, "Header");
                }}
                key={item.id}
                stretch
              />
            ) : null
          )}
      </Flex>
    );
  };

  export { CalendarButton };