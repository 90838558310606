"use client";
import React from "react";
import Image from "next/image";
import { rgba } from "polished";
import { useLocales, translationNamespace } from "../../hooks/useLocales";
import { openCookieControlPanel, qBankBaseUrl } from "@/utils";
import { trackFooterContactLinkClick } from "./utils";
import { GetFooterQueryResult } from "./operations.generated";

// components
import {
  Flex,
  Text,
  Accordion,
  CustomLink,
  UnderlinedLink,
  IconLink,
  Footer as UIFooter,
  FooterLinkStyled,
  Submenu,
  IconTypes,
  colors,
  VisuallyHidden,
} from "@/components/ui-components/src";
import { LanguageSwitch } from "../LanguageSwitch";

const Footer = ({ query }: { query: GetFooterQueryResult }) => {
  const miscLocales = useLocales(translationNamespace.common("Misc"), [
    "contactUs",
    "gothenburgOpera",
    "email",
    "telephone",
    "telephoneExchange",
    "opensInNewTab",
    "socialMediaTitle",
  ]);
  const footerLocales = useLocales(translationNamespace.common("Footer"), [
    "whatAreYouLookingFor",
    "manageCookies",
    "faqAccordionToggleLabel",
  ]);
  const languageSwitchLocales = useLocales(
    translationNamespace.common("LanguageSwitch"),
    ["primaryTitle", "secondaryTitle", "primaryUrl", "secondaryUrl", "label"]
  );
  const locales = {
    ...miscLocales,
    ...footerLocales,
  };
  const borderColor = colors["black"]
    ? rgba(colors["black"], 0.4)
    : "defaultColor";

  return (
    <>
      {!query.loading && query?.data?.footer ? (
        <UIFooter
          logo={
            <Image
              color="black"
              src={"/img/GOlogo-footer-black.png"/*`${qBankBaseUrl}/go_fallback-logo-500-footer.png`*/}
              alt="GöteborgsOperan logo"
              width="400"
              height="400"
              sizes={`256px`}
            />
          }
          leftContent={
            query.data.footer.faq && (
              <>
                <Text as="h2" variant="headline-3" mb={{ _: 2, l: 4 }}>
                  {locales.whatAreYouLookingFor}
                </Text>
                <Flex display="block" mb={{ _: 2.5, l: 4 }} w={1}>
                  <Accordion>
                    {query.data.footer.faq.map((faq: any, i: number) =>
                      faq?.items ? (
                        <React.Fragment key={i + "-" + faq?.title?.url}>
                          <Accordion.Header
                            id={`faq-${faq.title.url}`}
                            toggleLabel={locales.faqAccordionToggleLabel.replace(
                              /%s/g,
                              faq.title.title
                            )}
                          >
                            <CustomLink
                              href={faq.title.url}
                              label={faq.title.title}
                            />
                          </Accordion.Header>
                          <Accordion.Panel id={`faq-${faq.title.url}`}>
                            <Submenu>
                              <Submenu.List>
                                {faq.items.map((item: any, itemI: number) =>
                                  item?.url && item?.title ? (
                                    <Submenu.Item key={itemI}>
                                      <Submenu.Link href={item.url}>
                                        {item.title}
                                      </Submenu.Link>
                                    </Submenu.Item>
                                  ) : null
                                )}
                              </Submenu.List>
                            </Submenu>
                          </Accordion.Panel>
                        </React.Fragment>
                      ) : (
                        <Flex
                          key={i + "-" + faq.title.url}
                          w={1}
                          px={{ _: 0, m: 2.5 }}
                          py={2.5}
                          style={{
                            borderBottom: `1px solid`,
                            borderBottomColor: borderColor,
                          }}
                        >
                          <CustomLink
                            href={faq?.title.url}
                            label={faq?.title.title || ""}
                            withChevron={true}
                          />
                        </Flex>
                      )
                    )}
                  </Accordion>
                </Flex>
                {query.data.footer.faqPage && (
                  <Flex justifyContent="flex-end">
                    <UnderlinedLink.Link
                      href={query.data.footer.faqPage.url}
                      withIcon={true}
                      label={query.data.footer.faqPage.title}
                    />
                  </Flex>
                )}
              </>
            )
          }
          rightContent={
            <>
              <Text as="h2" variant="headline-3" mb={{ _: 2, l: 6 }}>
                {locales.contactUs}
              </Text>
              <Flex
                mb={0}
                flexDirection="row"
                flexWrap={{ _: "wrap", xl: "nowrap" }}
                justifyContent="space-between"
              >
                {query.data.footer.contactDepartments && (
                  <>
                    {query.data.footer.contactDepartments.map(
                      (contactDepartment: any, i: number) => (
                        <Flex
                          flex={{ _: "0 0 50%", l: "0 0 33%" }}
                          flexDirection="column"
                          justifyContent="space-between"
                          mb={6}
                          key={i + "-" + contactDepartment.departmentName.replace(/\s+/g, '')}
                        >
                          {contactDepartment.departmentName && (
                            <Flex w={0.6}>
                              <Text
                                as="h3"
                                fontSize={1.75}
                                lineHeight={{ _: 3, l: 2 }}
                                fontWeight="semibold"
                                mb={{ _: 2, l: 4 }}
                              >
                                {contactDepartment.departmentName}
                              </Text>
                            </Flex>
                          )}
                          <Flex flexDirection="column">
                            {contactDepartment.phone && (
                              <>
                                <VisuallyHidden id={`telephone-label-${i}`}>
                                  <p>{locales.telephone}</p>
                                </VisuallyHidden>
                                <Text
                                  fontSize={1.75}
                                  lineHeight={2.25}
                                  mb={1}
                                  aria-describedby={`telephone-label-${i}`}
                                >
                                  <FooterLinkStyled
                                    href={`tel:${contactDepartment.phone.replace(
                                      / /g,
                                      ""
                                    )}`}
                                  >
                                    {contactDepartment.phone}
                                  </FooterLinkStyled>
                                </Text>
                              </>
                            )}
                            {contactDepartment.email && (
                              <>
                                <VisuallyHidden id={`email-label-${i}`}>
                                  <p>{locales.email}</p>
                                </VisuallyHidden>
                                <Text
                                  fontSize={1.75}
                                  lineHeight={2.25}
                                  mb={0}
                                  aria-describedby={`email-label-${i}`}
                                >
                                  <FooterLinkStyled
                                    href={`mailto:${contactDepartment.email}`}
                                  >
                                    {contactDepartment.email}
                                  </FooterLinkStyled>
                                </Text>
                              </>
                            )}
                          </Flex>
                        </Flex>
                      )
                    )}
                  </>
                )}
              </Flex>
              {query.data.footer.contactInfoPage && (
                <Flex
                  justifyContent="flex-end"
                  mb={{ _: 3, l: 4 }}
                  mt={{ _: -4, l: 0 }}
                >
                  <UnderlinedLink.Link
                    href={query.data.footer.contactInfoPage.url}
                    withIcon={true}
                    label={query.data.footer.contactInfoPage.title}
                  />
                </Flex>
              )}
              <Text as="h2" variant="headline-3" mb={{ _: 2, l: 6 }}>
                {locales.socialMediaTitle}
              </Text>
              <Flex
                w={1}
                flexDirection={{ _: "column", xl: "row" }}
                justifyContent="space-between"
                mb={{ _: 4, l: 8 }}
              >
                {query.data.footer.operaSocialMedia && (
                  <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                    w={{ _: 1, xl: 0.5 }}
                    mb={{ _: 6, xl: 0 }}
                  >
                    {query.data.footer.operaSocialMediaTitle && (
                      <Flex w={{ _: 1, xl: 0.8 }}>
                        <Text
                          as="h3"
                          fontSize={1.75}
                          fontWeight="semibold"
                          mb={2.5}
                          lineHeight={{ _: 3, l: 2.25 }}
                        >
                          {query.data.footer.operaSocialMediaTitle}
                        </Text>
                      </Flex>
                    )}
                    <Flex flexWrap="wrap">
                      {query.data.footer.operaSocialMedia.map(
                        (socialMedia: any, i: number) => (
                          <Flex
                            key={i + "-" + socialMedia.url}
                            flex={{
                              _: "0 0 25%",
                              xs: "0 0 20%",
                              xl: "0 0 25%",
                            }}
                          >
                            <IconLink
                              icon={
                                socialMedia?.title.toLowerCase() as IconTypes
                              }
                              color="black"
                              size={4}
                              locales={{ label: socialMedia?.title || "" }}
                              aria-label={`${socialMedia?.title} (${locales.opensInNewTab})`}
                              href={socialMedia?.url}
                              target="_blank"
                              rel="nofollow noreferrer"
                              onClick={() =>
                                trackFooterContactLinkClick(
                                  "GöteborgsOperan",
                                  socialMedia?.title
                                )
                              }
                            />
                          </Flex>
                        )
                      )}
                    </Flex>
                  </Flex>
                )}
                {query.data.footer.danceCompanySocialMedia && (
                  <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                    w={{ _: 1, xl: 0.4 }}
                  >
                    {query.data.footer.danceCompanySocialMediaTitle && (
                      <Flex w={{ _: 1, xl: 0.8 }}>
                        <Text
                          as="h3"
                          fontSize={1.75}
                          fontWeight="semibold"
                          mb={{ _: 2, l: 4 }}
                          lineHeight={{ _: 3, l: 2.25 }}
                        >
                          {query.data.footer.danceCompanySocialMediaTitle}
                        </Text>
                      </Flex>
                    )}
                    <Flex
                      flexWrap="wrap"
                      justifyContent={{ xl: "space-between" }}
                    >
                      {query.data.footer.danceCompanySocialMedia.map(
                        (socialMedia: any, i: number) => (
                          <Flex
                            key={i + "-" + socialMedia.url}
                            flex={{
                              _: "0 0 25%",
                              xs: "0 0 20%",
                              xl: "0 0 33%",
                            }}
                          >
                            <IconLink
                              icon={
                                socialMedia?.title.toLowerCase() as IconTypes
                              }
                              color="black"
                              size={4}
                              locales={{ label: socialMedia?.title || "" }}
                              href={socialMedia?.url}
                              target="_blank"
                              rel="nofollow noreferrer"
                              aria-label={`${socialMedia?.title} (${locales.opensInNewTab})`}
                              onClick={() =>
                                trackFooterContactLinkClick(
                                  "GöteborgsOperan Danskompani",
                                  socialMedia?.title
                                )
                              }
                            />
                          </Flex>
                        )
                      )}
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </>
          }
          languageSwitch={
            <>
              <Flex justifyContent="center" alignItems="center" my={3}>
                <LanguageSwitch
                  id="language-switch-footer"
                  useLabel={!!languageSwitchLocales.label}
                />
              </Flex>
              <Flex display={{ _: "flex", l: "none" }}>
                {query.data.footer.regionLogo && (
                  <Flex
                    display="block"
                    mb={3}
                    maxw={{ _: 40, xs: 50 }}
                    w={1}
                    m="0 auto"
                  >
                    <div
                      style={{
                        position: "relative",
                        height: "80px",
                      }}
                    >
                      <Image
                        src={"/img/vgr_footer_black.png"/*`${qBankBaseUrl}/${query.data.footer.regionLogo.src}`*/}
                        alt={query.data.footer.regionLogo.alt || ""}
                        fill
                        style={{ objectFit: "contain" }}
                        sizes={`(max-width: 467px) 384px, 640px`}
                      />
                    </div>
                  </Flex>
                )}
              </Flex>
            </>
          }
          bottomContent={
            <>
              <Flex display={{ _: "none", l: "flex" }}>
                {query.data.footer.regionLogo && (
                  <Flex display="block" mb={3} maxw={50} w={1} m="0 auto">
                    <Image
                      src={"/img/vgr_footer_black.png"/*`${qBankBaseUrl}/${query.data.footer.regionLogo.src}`*/}
                      alt={query.data.footer.regionLogo.alt || ""}
                      width="400"
                      height="80"
                    />
                  </Flex>
                )}
              </Flex>
              <Flex
                flexWrap="wrap"
                justifyContent={{ _: "center", l: "start" }}
              >
                {query.data.footer.aboutSite && (
                  <Text mx={2}>
                    <a href={query.data.footer.aboutSite.url}>
                      {query.data.footer.aboutSite.title}
                    </a>
                  </Text>
                )}
                {query.data.footer.personalData && (
                  <Text mx={2}>
                    <a href={query.data.footer.personalData.url}>
                      {query.data.footer.personalData.title}
                    </a>
                  </Text>
                )}
                {query.data.footer.termsPage && (
                  <Text mx={2}>
                    <a href={query.data.footer.termsPage.url}>
                      {query.data.footer.termsPage.title}
                    </a>
                  </Text>
                )}
                <Text.Anchor
                  href="#"
                  mx={2}
                  onClick={(e: React.UIEvent) => {
                    e.preventDefault();
                    openCookieControlPanel();
                  }}
                >
                  {locales.manageCookies}
                </Text.Anchor>
              </Flex>
            </>
          }
        ></UIFooter>
      ) : null}
    </>
  );
};

export { Footer };
