import * as React from "react";
import Link from "next/link";
import Image from "next/image";
import { ClientThemeProvider as ThemeProvider } from "@/components/ClientThemeProvider";
import { CommonSearchResultItem } from "../../../@types/graphql.generated";
import { ColorTheme } from "@/components/ui-components/src/@types/styled";
import { qBankImageSrc } from "../../../utils";
import {
  Button,
  Flex,
  mergeColorTheme,
  SearchResultItem,
  Text,
} from "@ui-components";
import { headlineFont } from "@fonts";

interface SearchResultItemShowProps {
  result: CommonSearchResultItem;
  index: number;
}

export const SearchResultItemShow = React.forwardRef<
  HTMLAnchorElement,
  SearchResultItemShowProps
>((props, ref) => {
  if (props.result.theme) {
    return (
      <ThemeProvider theme={mergeColorTheme(props.result.theme as ColorTheme)}>
        <SearchResultItem
          isShow
          image={
            props?.result?.image?.src ? (
              <Image
                src={qBankImageSrc(props.result.image.src, "768")}
                alt={props.result.image.alt || ""}
                width="768"
                height="512"
                sizes="(min-width: 385px) and (max-width: 640px) 640px, (min-width: 641px) and (max-width: 768px) 768px, 384px"
                priority={props.index <= 1}
              />
            ) : null
          }
        >
          <Flex
            p={{ _: 2.5, m: 0 }}
            flexDirection="column"
            alignItems={{ _: "center", m: "flex-start" }}
          >
            <SearchResultItem.Header isShow>
              {props.result.tag && (
                <SearchResultItem.Tag isShow>
                  {props.result.tag}
                </SearchResultItem.Tag>
              )}
              {props.result.dateRange && props.result.dateRange.from && (
                <SearchResultItem.Date isShow>
                  {props.result.dateRange.to
                    ? `${props?.result?.dateRange?.from?.formatted?.date} - ${props?.result?.dateRange?.to?.formatted?.date}`
                    : props?.result?.dateRange?.from?.formatted?.date}
                </SearchResultItem.Date>
              )}
            </SearchResultItem.Header>

            {props.result.title && (
              <Link
                ref={ref}
                href={props.result.title.url}
                className={headlineFont.className}
                prefetch={false}
              >
                <Text
                  as="h2"
                  fontSize={{ _: 2.25, m: 3 }}
                  lineHeight={{ _: 3, m: 4 }}
                  style={{ textDecoration: "underline" }}
                  textAlign={{ _: "center", m: "left" }}
                >
                  {props.result.title.title}
                </Text>
              </Link>
            )}

            {props.result.preamble && (
              <SearchResultItem.Content className={headlineFont.className}>
                <Text
                  textAlign={{ _: "center", m: "left" }}
                  fontSize={{ _: 3, m: 4 }}
                  lineHeight={{ _: 4, m: 5 }}
                  mt={1}
                  mb={0}
                  truncate="twoLine"
                >
                  {props.result.preamble}
                </Text>
              </SearchResultItem.Content>
            )}
            {props.result.ticketUrl && (
              <Flex mt={2} mb={{ _: 4, m: 0 }}>
                {props.result.ticketUrl.__typename === "link" ? (
                  <Button.Anchor
                    backgroundColor="cyan-blue"
                    textColor="black-light"
                    href={props.result.ticketUrl.url}
                    target={`_${props.result.ticketUrl.target}`}
                    rel="nofollow noreferrer"
                    rounded
                    size="large"
                    label={props.result.ticketUrl.title}
                  />
                ) : props.result.ticketUrl.__typename === "stringBox" ? (
                  <Text
                    textColor={props?.result?.theme?.dark ? "white" : "black"}
                    fontWeight="semibold"
                    mb={0}
                  >
                    {props?.result?.ticketUrl?.value}
                  </Text>
                ) : null}
              </Flex>
            )}
          </Flex>
        </SearchResultItem>
      </ThemeProvider>
    );
  }
});
SearchResultItemShow.displayName = "SearchResultItemShow";
