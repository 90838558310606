import React from 'react';
import { Megamenu as IMegaMenu } from "../../../@types/graphql.generated";
import { Flex, CustomLink, CustomNoLink, Submenu } from '@/components/ui-components/src';
import { trackMegaMenuClickEvent } from '../utils';

const MENU_ITEM_WIDTH = 360;

interface MegaMenuItemProps {
  item: IMegaMenu;
}

const MegaMenuItem: React.FC<MegaMenuItemProps> = ({ item }) => {
  return (
    <Flex
      width={MENU_ITEM_WIDTH}
      flexDirection="column"
      mb={item.children?.length ? 4 : 0}
      minw={43}
      key={item.id}
    >
      <Flex mb={2} mr={8}>
        {item.url ? (
          <CustomLink
            href={item.url}
            label={item.title}
            withChevron={false}
            onClick={() => trackMegaMenuClickEvent(item.title, item.url)}
          />
        ) : (
          <CustomNoLink label={item.title} withChevron={false} />
        )}
      </Flex>
      {(item.children?.length ?? 0) > 0 && (
        <Flex flexDirection="column" mr={8}>
          <Submenu>
            <Submenu.List>
              {item.children?.map((link, idx) => {
                return (
                  <Submenu.Item key={`${item.id}-${link.id}-${idx}`} pr={4}>
                    <Submenu.Link
                      href={link.url || "#"}
                      underline={link.highlighted}
                      nolink={!link.url}
                      onClick={() => {
                        if (link.url) trackMegaMenuClickEvent(link.title, link.url);
                      }}
                    >
                      {link.title}
                    </Submenu.Link>
                  </Submenu.Item>
                );
              })}
            </Submenu.List>
          </Submenu>
        </Flex>
      )}
    </Flex>
  );
};

export default MegaMenuItem;