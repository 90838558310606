import { headingFontClass } from "@/components/ui-components/src";

const createMarkup = (markup: string) => {
  // Replace heading tags to add className
  const headingRegex = /<(h[1-6])(.*?)>(.*?)<\/\1>/gi;
  let modifiedMarkup = markup.replace(headingRegex, (match, p1, p2, p3) => {
    return `<${p1} ${p2} class="${headingFontClass}">${p3}</${p1}>`;
  });

  // Replace <img> tags to add width, height, and alt attributes
  const imgRegex = /<img(.*?)\/?>/gi;
  modifiedMarkup = modifiedMarkup.replace(imgRegex, (match, p1) => {
    const srcMatch = p1.match(/src="(.*?)"/);
    const altMatch = p1.match(/alt="(.*?)"/);

    const src = srcMatch ? srcMatch[1] : "";
    const alt = altMatch ? altMatch[1] : "";

    return `<img src="${src}" alt="${alt}" width="768" height="512" />`;
  });

  return { __html: modifiedMarkup };
};

export default createMarkup;
